<template>
  <v-container grid-list-md>
    <v-row>
      <v-col v-if="!appId" cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Study Abroad Application</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>You currently do not have an active study abroad application. Click the link below to start a new application.</v-card-text>
          <v-card-actions>
            <v-dialog v-model="startAppDialog" width="400">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">Start Application</v-btn>
              </template>
              <v-card>
                <v-card-title>Start Application</v-card-title>
                <v-card-text>
                  <p>To begin your Study Abroad application, please enter the term for which you are planning to study abroad. You will be able to change this later if your plans change.</p>
                  <v-select v-model="appTerm" :items="termOptions" label="Term of Program" outlined></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="startApplication">Start application</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text @click="startAppDialog = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-else-if="appStatus === 'Unsubmitted'" cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Study Abroad Application: {{ appProgramTerm || 'Pending' }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>You have not yet submitted this application.
            <div v-if="appProgramTerm">You app must be submitted by {{ dueDate(appProgramTerm) }} to be eligible for study abroad in {{ appProgramTerm }}.</div>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="'/student/study-abroad/' + appId">Continue application</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-else-if="appStatus === 'Returned'" cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Study Abroad Application: {{ appProgramTerm || 'Pending' }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>Your application has been returned for you to make changes. Please click the button below and make the needed changes as soon as possible.
            <div v-if="appProgramTerm">You app must be submitted by {{ dueDate(appProgramTerm) }} to be eligible for study abroad in {{ appProgramTerm }}.</div>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="'/student/study-abroad/' + appId">Continue application</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <home-app-status v-else :app-id="appId" @statusChanged="setStatus"></home-app-status>
    </v-row>
  </v-container>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    HomeAppStatus: () => import('@/components/student/study-abroad/HomeAppStatus')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles || [])
    const size = computed(() => root.$vuetify.breakpoint)
    const appId = ref(null)
    const appStatus = ref(null)
    const appSubmitted = ref(false)
    const appProgramTerm = ref(null)
    const startAppDialog = ref(false)
    const appTerm = ref('')
    const termOptions = ref([])

    async function startApplication () {
      let { _id: userId, pidm, bannerId, username, preferred, last } = user.value
      // Look up the student in the term detail to get their major(s) and grad year
      const { data: tdData } = await root.$feathers.service('student/term-detail').find({ query: { pidm, $sort: { term: -1 }, $limit: 1 } })
      let majors = []
      let gradYear = ''
      let advisor = ''
      if (tdData.length > 0) {
        majors = tdData[0].academics.major
        const dt = new Date(tdData[0].academics.expGradDate)
        gradYear = dt.getFullYear()
        advisor = 'advisor' in tdData[0].academics && Array.isArray(tdData[0].academics.advisor) && tdData[0].academics.advisor.length > 0 ? tdData[0].academics.advisor[0].name : ''
      }
      let name = preferred + ' ' + last
      // Get the current revision of the form; that is the one they will be connected to
      const form = await root.$feathers.service('forms/base').get('60f1cdb2ec295937a80ac90c')
      // Start a form submission for them; we will update this form submission as they go
      const { _id: formSubmission } = await root.$feathers.service('forms/submission').create({
        user: userId,
        name,
        form: {
          name: form.name,
          base: form._id,
          revision: form.revisions.active
        },
        dataArr: [
          {
            field: 'Name',
            value: name
          },
          {
            field: 'Graduation Year',
            value: gradYear
          },
          {
            field: 'Major(s)',
            value: majors
          },
          {
            field: 'Academic Advisor',
            value: advisor
          },
          {
            field: 'Intended Study Abroad Term',
            value: appTerm.value
          }
        ]
      })
      // Create the study abroad submission which will include the form submission
      const { _id } = await root.$feathers.service('student/study-abroad').create({ pidm, bannerId, username, name, term: appTerm.value, formSubmission, status: 'Unsubmitted' })
      // Route to the application
      root.$router.push('/student/study-abroad/' + _id)
    }

    function dueDate (programTerm) {
      let [ term, year ] = programTerm.split(' ')
      if (term === 'Spring') {
        // Due date for spring is November 1
        return 'November 1, ' + year
      } else {
        // Due date for summer and fall is April 30 of the year
        return 'April 30, ' + year
      }
    }

    onMounted(async () => {
      if ('Employee' in roles.value) {
        root.$router.replace('/student/study-abroad/admin')
        return
      }
      const { data } = await root.$feathers.service('student/study-abroad').find({ query: { pidm: user.value.pidm } })
      if (data.length > 0) {
        appStatus.value = data[0].status
        appId.value = data[0]._id
      } else {
        const { options, service, query: q, textField, valueField } = await root.$feathers.service('forms/select-options').get('60f1d1e0ec295937a80ac931')
        if (options && Array.isArray(options) && options.length > 0) {
          // ...
        } else {
          const dt = new Date()
          const query = JSON.parse(q.replace('_now_', dt.toISOString()))
          const { data } = await root.$feathers.service(service).find({ query })
          termOptions.value = data.map((row) => { return { text: row[textField], value: row[valueField] } })
        }
      }
    })

    function setStatus (newStatus) {
      console.log(newStatus)
      appStatus.value = newStatus
    }

    return {
      user,
      roles,
      size,
      appId,
      appStatus,
      appSubmitted,
      appProgramTerm,
      startAppDialog,
      appTerm,
      termOptions,
      startApplication,
      dueDate,
      setStatus
    }
  }
}
</script>
